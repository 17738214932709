var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trips-table"},[_c('v-row',{staticClass:"results-header"},[_c('v-col',{staticClass:"d-flex"},[_c('h2',[_c('b',[_vm._v("Reservations")])]),_c('h3',{staticClass:"ml-4 results-count"},[_vm._v(" "+_vm._s(_vm.filteredReferrals.length)+" Results ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredReferrals,"item-key":"referral","rows-per-page-items":_vm.rowsPerPageItems,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(heads){return _vm._l((heads.headers),function(headerItem,headerIndex){return _c('th',{key:headerIndex,class:`column text-xs-${
          headerItem.align ? headerItem.align : 'left'
        }`,attrs:{"role":"columnheader","scope":"col"},on:{"click":function($event){return _vm.sortByColumn(headerItem.value)}}},[_vm._v(" "+_vm._s(headerItem.text)+" "),_c('span',{key:`data-table-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[(
              _vm.pagination.sortBy &&
              _vm.pagination.sortBy === headerItem.value &&
              _vm.pagination.descending === true
            )?_c('CRIcon',{staticStyle:{"margin-bottom":"-4px"},attrs:{"width":16,"height":16,"view-box":"0 0 24 24"}},[_vm._v(" arrow_upward ")]):(
              _vm.pagination.sortBy &&
              _vm.pagination.sortBy === headerItem.value &&
              _vm.pagination.descending === false
            )?_c('CRIcon',{staticStyle:{"margin-bottom":"-4px"},attrs:{"width":16,"height":16,"view-box":"0 0 24 24"}},[_vm._v(" arrow_downward ")]):_c('v-icon',{staticStyle:{"width":"16px"},attrs:{"small":""}},[_vm._v("fa-fw")])],1)])})}},{key:"items",fn:function(props){return [_c('td',{key:`managed_id-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_c('a',{attrs:{"href":`/reservations/${props.item.managedId.split('.')[0]}`,"target":"_blank"}},[_vm._v(" "+_vm._s(props.item.managedId)+" ")])]),_c('td',{key:`pickup-time-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(_vm.getLocalTime(props.item.pickupTime))+" ")]),_c('td',{key:`company-name-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`,staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(props.item.companyName)+" ")]),_c('td',{key:`driver-status-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.driverStatus)+" ")]),_c('td',{key:`vehicle-status-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.vehicleStatus)+" ")]),_c('td',{key:`tracking-status-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.trackingStatus)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }