<template>
  <Sheet>
    <h1><b>Trip Monitoring</b></h1>
    <h3><b>Trips summary</b></h3>
    <div class="issue-cards">
      <div
        @click="toggleIssueFilter('driver')"
        :class="['issue-card', { clicked: selectedCards.includes('driver') }]"
      >
        <h3>Driver Issues</h3>
        <p>
          <span class="issue-count">{{ driverIssueCount }}</span>
          trips
        </p>
      </div>
      <div
        @click="toggleIssueFilter('tracking')"
        :class="['issue-card', { clicked: selectedCards.includes('tracking') }]"
      >
        <h3>Tracking Issues</h3>
        <p>
          <span class="issue-count">{{ trackingIssueCount }}</span>
          trips
        </p>
      </div>
      <div
        @click="toggleIssueFilter('vehicle')"
        :class="['issue-card', { clicked: selectedCards.includes('vehicle') }]"
      >
        <h3>Vehicle Issues</h3>
        <p>
          <span class="issue-count">{{ vehicleIssueCount }}</span>
          trips
        </p>
      </div>
    </div>
    <TripMonitoringTable :filteredReferrals="filteredReferrals" />
  </Sheet>
</template>

<script>
import Sheet from '@/layouts/Sheet.vue'
import TripMonitoringTable from './TripMonitoringTable.vue'
import { DateTime } from 'luxon'
import { getTripMonitoringReferrals } from '@/services/reservations'

export default {
  components: {
    Sheet,
    TripMonitoringTable,
  },
  data() {
    return {
      selectedCards: [],
      referrals: [],
      filterDriverIssues: false,
      filterTrackingIssues: false,
      filterVehicleIssues: false,
    }
  },
  computed: {
    filteredReferrals() {
      return this.referrals.filter(
        (referral) =>
          (this.filterDriverIssues ? referral.driverStatus === '' : true) &&
          (this.filterTrackingIssues ? referral.trackingStatus === '' : true) &&
          (this.filterVehicleIssues ? referral.vehicleStatus === '' : true)
      )
    },
    driverIssueCount() {
      return 20
    },
    vehicleIssueCount() {
      return 15
    },
    trackingIssueCount() {
      return 10
    },
  },
  methods: {
    toggleIssueFilter(type) {
      if (this.selectedCards.includes(type)) {
        this.selectedCards = this.selectedCards.filter((card) => card !== type)
      } else {
        this.selectedCards.push(type)
      }

      if (type === 'driver') this.filterDriverIssues = !this.filterDriverIssues
      else if (type === 'tracking')
        this.filterTrackingIssues = !this.filterTrackingIssues
      else if (type === 'vehicle')
        this.filterVehicleIssues = !this.filterVehicleIssues
    },
    async fetchReferrals() {
      const now = DateTime.local()
      const startOfDay = now.startOf('day')
      const endOfDay = now.endOf('day')

      const startOfNextDay = startOfDay.plus({ days: 1 })
      const endOfNextDay = endOfDay.plus({ days: 1 })

      const startOfNextDayUTC = startOfNextDay
        .toUTC()
        .toFormat('yyyy-MM-dd HH:mm:ss')
      const endOfNextDayUTC = endOfNextDay
        .toUTC()
        .toFormat('yyyy-MM-dd HH:mm:ss')

      const tableResp = await getTripMonitoringReferrals(
        startOfNextDayUTC,
        endOfNextDayUTC
      )
      this.referrals = tableResp.data.referrals
    },
  },
  async mounted() {
    this.fetchReferrals()
  },
}
</script>
<style lang="scss" scoped>
.trip-summary {
  font-family: Arial, sans-serif;
  max-width: 500px;
  margin: auto;
}

.issue-cards {
  display: flex;
  gap: 0.4em;
  align-items: center;
}

.issue-card {
  border: 2px solid $border-gray;
  border-radius: 8px;
  padding: 0.6em 0.6em;
  text-align: left;
  flex: 1;
  max-width: 150px;
  max-height: 80px;
  margin-top: 0.6em;
  margin-bottom: 1em;
}

.issue-card:hover {
  background-color: $background-hover;
}

.issue-card.clicked {
  border-color: $blue;
  background-color: $background-hover;
}

.issue-card h3 {
  font-size: 0.9em;
}

.issue-card p {
  font-size: 1.3em;
  font-weight: bold;
}

.issue-count {
  font-size: 1.6em;
}
</style>
