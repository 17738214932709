<template>
  <div class="trips-table">
    <v-row class="results-header">
      <v-col class="d-flex">
        <h2><b>Reservations</b></h2>
        <h3 class="ml-4 results-count">
          {{ filteredReferrals.length }} Results
        </h3>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredReferrals"
      item-key="referral"
      :rows-per-page-items="rowsPerPageItems"
      :pagination.sync="pagination"
    >
      <template #headers="heads">
        <th
          v-for="(headerItem, headerIndex) in heads.headers"
          :key="headerIndex"
          role="columnheader"
          scope="col"
          :class="`column text-xs-${
            headerItem.align ? headerItem.align : 'left'
          }`"
          @click="sortByColumn(headerItem.value)"
        >
          {{ headerItem.text }}
          <span
            :key="`data-table-sort-key-${pagination.sortBy}-${pagination.descending}`"
          >
            <CRIcon
              v-if="
                pagination.sortBy &&
                pagination.sortBy === headerItem.value &&
                pagination.descending === true
              "
              :width="16"
              :height="16"
              view-box="0 0 24 24"
              style="margin-bottom: -4px"
            >
              arrow_upward
            </CRIcon>
            <CRIcon
              v-else-if="
                pagination.sortBy &&
                pagination.sortBy === headerItem.value &&
                pagination.descending === false
              "
              :width="16"
              :height="16"
              view-box="0 0 24 24"
              style="margin-bottom: -4px"
            >
              arrow_downward
            </CRIcon>
            <v-icon v-else small style="width: 16px">fa-fw</v-icon>
          </span>
        </th>
      </template>
      <template #items="props">
        <td
          :key="`managed_id-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          <a
            :href="`/reservations/${props.item.managedId.split('.')[0]}`"
            target="_blank"
          >
            {{ props.item.managedId }}
          </a>
        </td>
        <td
          :key="`pickup-time-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ getLocalTime(props.item.pickupTime) }}
        </td>
        <td
          :key="`company-name-sort-key-${pagination.sortBy}-${pagination.descending}`"
          style="text-align: center;"
        >
          {{ props.item.companyName }}
        </td>
        <td
          :key="`driver-status-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.driverStatus }}
        </td>
        <td
          :key="`vehicle-status-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.vehicleStatus }}
        </td>
        <td
          :key="`tracking-status-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.trackingStatus }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    filteredReferrals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rowsPerPageItems: [...Array(10).keys()].map((x) => (x + 1) * 10),
      headers: [
        { text: 'Reservation ID', value: 'managedId' },
        { text: 'Pickup Time', value: 'pickupTime' },
        { text: 'Company Name', value: 'companyName' },
        { text: 'Driver Status', value: 'driverStatus' },
        { text: 'Vehicle Status', value: 'vehicleStatus' },
        { text: 'Tracking Status', value: 'trackingStatus' },
        { text: 'Contact Status', value: 'contactStatus' },
        { text: 'Last Contact', value: 'lastContact' },
        { text: 'Logs', value: 'logs' },
        { text: 'Assigned To', value: 'assignedTo' },
      ],
      referrals: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'pickupTime',
      },
    }
  },
  methods: {
    sortByColumn(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
      this.$forceUpdate()
    },
    getLocalTime(timestamp) {
      return DateTime.fromISO(timestamp).setZone('local').toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
.results-count {
  font-weight: 300;
}
.trips-table {
  border: 1px solid $border-gray;
  border-radius: 10px;
  padding: 20px;
}
</style>
